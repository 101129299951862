import lessVars from '@/assets/style/defaultThemes.less';
// 人员角色
export enum EnumUserRole {
  SUPER_ADMIN = 'SUPER_ADMIN', // 超级管理员
  LIGHT_ADMIN = 'LIGHT_ADMIN', // 照明管理员
  LIGHT_VIEWER = 'LIGHT_VIEWER', // 照明查看者
  AIR_CON_ADMIN = 'AIR_CON_ADMIN', // 空调管理员
  AIR_CON_VIEWER = 'AIR_CON_VIEWER', // 空调查看者
  CARBON_EMISSION_VIEWER = 'CARBON_EMISSION_VIEWER', // 碳排查看者
  CARBON_EMISSION_ADMIN = 'CARBON_EMISSION_ADMIN', // 碳排管理者
  ENERGY_ANALYSIS_VIEW = 'ENERGY_ANALYSIS_VIEW', // 能耗分析查看者
}
export const UserRoleList: { value: EnumUserRole; label: string }[] = [
  { value: EnumUserRole.LIGHT_ADMIN, label: '照明管理员' },
  { value: EnumUserRole.LIGHT_VIEWER, label: '照明查看者' },
  { value: EnumUserRole.AIR_CON_ADMIN, label: '空调管理员' },
  { value: EnumUserRole.AIR_CON_VIEWER, label: '空调查看者' },
  { value: EnumUserRole.SUPER_ADMIN, label: '超级管理员' },
  { value: EnumUserRole.CARBON_EMISSION_VIEWER, label: '碳排查看者' },
  { value: EnumUserRole.CARBON_EMISSION_ADMIN, label: '碳排管理者' },
  { value: EnumUserRole.ENERGY_ANALYSIS_VIEW, label: '能耗分析查看者' },
];
// 人员状态
export enum EnumUserStatus {
  ACTIVE = 1, // 活动
  FREEZE = 2, // 冻结
}
// 灯组区域类型
export enum EnumLampAreaType {
  FLIGHT_LINKAGE = 'FLIGHT_LINKAGE',
  TIME_SCHEDULE = 'TIME_SCHEDULE',
}
// 灯组的控制类型
export enum EnumControlType {
  CONTROL_SWITCH = 'CONTROL_SWITCH', // 开关
  CONTROL_BRIGHTNESS = 'CONTROL_BRIGHTNESS', // 亮度
}

// 灯组状态
export enum EnumGroupStatus {
  CLOSE = 0,
  OPEN = 1,
  ERROR = -1,
}
// 灯组的控制模式
export enum EnumGroupMode {
  AUTOMATIC = 0, // 自动
  MANUAL = 1, // 手动
  // 下面两个目前只有国际远机位出发大厅使用
  AUTO_SINGLE_FLIGHT = 2, // 自动-单航班
  AUTO_MULTIPLE_FLIGHT = 3, // 自动-多航班
}
export const EnumGroupModeList: { mode: EnumGroupMode; name: string }[] = [
  { mode: EnumGroupMode.AUTOMATIC, name: '自动' },
  { mode: EnumGroupMode.MANUAL, name: '手动' },
  { mode: EnumGroupMode.AUTO_SINGLE_FLIGHT, name: '自动-单航班' },
  { mode: EnumGroupMode.AUTO_MULTIPLE_FLIGHT, name: '自动-多航班' },
];
// 快捷操作
export enum EnumGroupShortcut {
  CLOSE = 0,
  OPEN = 1,
  SCHEME = 2,
}
// 转盘状态
export enum EnumTurntableStatus {
  NONE = -1,
  CLOSE = 0,
  OPEN = 1,
  ERROR = 2,
  STOP = 3, // 紧急制动
}
export const TurntableStatusList: { status: EnumTurntableStatus; color: string; name: string }[] = [
  { status: EnumTurntableStatus.OPEN, color: lessVars.bdiaSuccess, name: '开启' },
  { status: EnumTurntableStatus.CLOSE, color: lessVars.bdiaClose, name: '关闭' },
  { status: EnumTurntableStatus.ERROR, color: lessVars.bdiaError, name: '离线' },
  { status: EnumTurntableStatus.STOP, color: lessVars.bdiaStop, name: '紧急制动' },
  { status: EnumTurntableStatus.NONE, color: lessVars.bdiaClose, name: '空' },
];
// 用户登录-记住我
export enum EnumRemember {
  Y = 1,
  N = 0,
}

// 空调相关
// 控制季节
export enum EnumSeason {
  SPRING = 'SPRING',
  SUMMER = 'SUMMER',
  WINTER = 'WINTER',
  AUTUMN = 'AUTUMN',
}
// 空调的控制模式
export enum ACEnumGroupMode {
  AUTOMATIC = 0, // 自动
  MANUAL = 1, // 手动
}
// 空调机组使用状态
export enum EnumACUseStatus {
  ENABLE = 'ENABLE', // 启用
  DISABLE = 'DISABLE', // 禁用
}
export const ACUseStatusList: { type: EnumACUseStatus; name: string }[] = [
  { type: EnumACUseStatus.ENABLE, name: '启用' },
  { type: EnumACUseStatus.DISABLE, name: '禁用' },
];
// 报警相关
export enum EnumAlarm {
  GROUP = 'GROUP',
  SYSTEM = 'SYSTEM',
  AIR_CON_UNIT = 'AIR_CON_UNIT',
  DEVICE = 'DEVICE',
}
export const AlarmTypeList: { type: EnumAlarm; name: string }[] = [
  { type: EnumAlarm.GROUP, name: '灯组异常' },
  { type: EnumAlarm.DEVICE, name: '设备异常' },
  { type: EnumAlarm.SYSTEM, name: '系统异常' },
];

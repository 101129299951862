import { Dropdown, Menu } from 'antd';
import { cloneDeep, last } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDialog from '../UserDialog/UserDialog';
import Season from './comps/Season/Season';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import LogoPng from '@/assets/imgs/logo.png';
import './Header.less';
import { logout } from '@/api/login';
import BdiaAlarm from '@/components/BdiaAlarm/BdiaAlarm';
import BdiaConfirm from '@/components/BdiaConfirm/BdiaConfirm';
import { toggleLoading, updateControlTimeRange, updateSelecteYear } from '@/store/features/commonSlice';
import { EnumSeason, EnumUserRole } from '@/typings/enum';
import { getSeason, getAreaList as getAcAreaList, getAcFloorSpace } from '@/api/acControl';
import BdiaSelect from '@/components/BdiaSelect/BdiaSelect';
import { FloorAreaTreeItemType, updateCurrentArea as updateLampCurrentArea } from '@/store/features/lampSlice';
import { updateAcCurrentArea, updateAcTempCtrlAttrs, updateAcTempRange } from '@/store/features/acSlice';
import { PROJECT_MENU } from '@/config/project.dx';
import { getSysPlanConfig } from '@/api/lampSystem';
import { getAreaList, getFloorAreaList as getLampFloorAreaList } from '@/api/lampControl';
import { getAreaSetting } from '@/api/acSystem';
import ws from '@/utils/webSocketClass';
import BdiaCascader from '@/components/BdiaCascader/BdiaCascader';

const SEC_VISIBLE: {
  [key: string]: {
    season: EnumSeason | false;
  } & {
    [key: string]: boolean;
  };
} = {
  '/select': {
    home: false,
    setting: true,
    season: false,
    year: false,
    lampArea: false,
    acArea: false,
  },
  '/login': {
    home: false,
    setting: false,
    season: false,
    year: false,
    lampArea: false,
    acArea: false,
  },
  '/user': {
    home: true,
    setting: false,
    season: false,
    year: false,
    lampArea: false,
    acArea: false,
  },
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const alarmVisible = useAppSelector((state) => state.common.alarmVisible);
  const { userinfo, selectYear, controlTimeRange } = useAppSelector((state) => state.common);
  const {
    areaList: lampArea,
    currentArea: { id: curLampArea },
    floorAreaTree: lampFloorAreaTree,
    floorAreaList: lampFloorAreaList,
  } = useAppSelector((state) => state.lamp);
  const curLampAreaRef = useRef(curLampArea);
  const {
    areaList: acArea,
    currentArea: { id: curAcArea },
    acFloorSpaceList,
    acFloorSpaceTree,
  } = useAppSelector((state) => state.ac);
  const curAcAreaRef = useRef(curAcArea);
  const editAuth = userinfo.role.some((r) => r === EnumUserRole.SUPER_ADMIN);

  const [homeVisible, setHomeVisible] = useState(false); // 返回主页
  const [lampAreaVisible, setLampAreaVisible] = useState(false); // 区域切换select
  const [curLampFloorArea, setCurLampFloorArea] = useState<number[]>([]);
  const [acAreaVisible, setAcAreaVisible] = useState(false);
  const [curAcFloorArea, setCurAcFloorArea] = useState<number[]>([]);
  const [timeRangeStr, setTimeRangeStr] = useState<string>('');
  const [settingVisible, setSettingVisible] = useState(false); // 设置按钮
  const [userDialogVisible, setUserDialogVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [season, setSeason] = useState<EnumSeason | false>();
  const [yearVisible, setYearVisible] = useState<boolean>(false);
  const [yearList, setYearList] = useState<{ label: number; value: number }[]>([]);

  useEffect(() => {
    ws.unSubscribeWS(9, 'common');
    ws.unSubscribeWS(10, 'common');
    const { pathname } = location;
    if (SEC_VISIBLE[pathname]) {
      const cur = SEC_VISIBLE[pathname];
      setHomeVisible(cur.home);
      setSettingVisible(cur.setting);
      setSeason(cur.season);
      setYearVisible(cur.year);
      setLampAreaVisible(cur.lampArea);
      setAcAreaVisible(cur.acArea);
    } else {
      const moduleName = pathname.split('/')[1];
      if (PROJECT_MENU[moduleName]) {
        const curMenu = PROJECT_MENU[moduleName].find((item) => item.path === pathname);
        const curHeaders = curMenu?.headers || [];
        setHomeVisible(curHeaders.indexOf('home') >= 0);
        setYearVisible(curHeaders.indexOf('year') >= 0);
        setLampAreaVisible(curHeaders.indexOf('lampArea') >= 0);
        setAcAreaVisible(curHeaders.indexOf('acArea') >= 0);
        setSettingVisible(curHeaders.indexOf('setting') >= 0);
        if (moduleName === 'ac') {
          handleGetSeason();
          ws.subscribeWS(10, 'common', (msg: any) => {
            const { startTime, endTime, areaId } = msg.data;
            if (areaId === curAcAreaRef.current) {
              if (!startTime && !endTime) {
                dispatch(updateControlTimeRange(null));
              } else {
                dispatch(updateControlTimeRange([startTime, endTime]));
              }
            }
          });
        } else if (moduleName === 'lamp') {
          ws.subscribeWS(9, 'common', (msg: any) => {
            const { startTime, endTime, areaId } = msg.data;
            if (areaId === curLampAreaRef.current) {
              if (!startTime && !endTime) {
                dispatch(updateControlTimeRange(null));
              } else {
                dispatch(updateControlTimeRange([startTime, endTime]));
              }
            }
          });
        } else if (moduleName === 'carbon') {
          generateYears();
          ws.safeClose();
        } else {
          ws.safeClose();
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (!controlTimeRange) {
      setTimeRangeStr('航班联动未启用');
    } else if (controlTimeRange[0] === controlTimeRange[1]) {
      setTimeRangeStr('全天');
    } else {
      setTimeRangeStr(`${controlTimeRange[0]}-${controlTimeRange[1]}`);
    }
  }, [controlTimeRange]);

  useEffect(() => {
    if (lampAreaVisible) {
      if (!lampFloorAreaTree.length) {
        dispatch(getLampFloorAreaList());
      }
      if (!lampArea.length) {
        dispatch(getAreaList());
      }
    }
  }, [lampAreaVisible]);

  useEffect(() => {
    if (acAreaVisible) {
      if (!acFloorSpaceList.length) {
        dispatch(getAcFloorSpace());
      }
      if (!acArea.length) {
        dispatch(getAcAreaList());
      }
    }
  }, [acAreaVisible]);

  useEffect(() => {
    if (lampAreaVisible) {
      curLampAreaRef.current = curLampArea;
      if (curLampArea) {
        const floorAreaId = lampFloorAreaList.find((item) => item.controlAreaId === curLampArea)?.id;
        if (floorAreaId) {
          const ids: number[] = [];
          getPids(floorAreaId, ids, lampFloorAreaList);
          setCurLampFloorArea(ids);
        }
      } else {
        setCurLampFloorArea([]);
      }

      if (lampFloorAreaTree.length && lampArea.length) {
        if (!curLampArea) {
          const first = lampFloorAreaTree[0].children?.[0];
          if (first) {
            const curArea = lampArea.find((item) => item.id === first.controlAreaId);
            dispatch(updateLampCurrentArea(cloneDeep(curArea)));
          }
        } else {
          getSysPlanConfig({ areaId: curLampArea }).then((res) => {
            if (res.code === 0) {
              const { controlTime } = res.data;
              if (!controlTime.startTime && !controlTime.endTime) {
                dispatch(updateControlTimeRange(null));
              } else {
                dispatch(updateControlTimeRange([controlTime.startTime, controlTime.endTime]));
              }
            }
          });
        }
      }
    }
  }, [lampAreaVisible, lampFloorAreaTree, lampArea, curLampArea]);

  useEffect(() => {
    if (acAreaVisible && curAcArea) {
      getAreaSetting({ areaId: curAcArea }).then((res) => {
        if (res.code === 0) {
          const { controlTime } = res.data;
          if (!controlTime.startTime && !controlTime.endTime) {
            dispatch(updateControlTimeRange(null));
          } else {
            dispatch(updateControlTimeRange([controlTime.startTime, controlTime.endTime]));
          }
        }
      });
    }
  }, [acAreaVisible, curAcArea]);

  useEffect(() => {
    if (acAreaVisible) {
      curAcAreaRef.current = curAcArea;
      if (curAcArea) {
        const tmpId = acFloorSpaceList.find((item) => item.controlAreaId === curAcArea)?.id;
        if (tmpId) {
          const ids: number[] = [];
          getPids(tmpId, ids, acFloorSpaceList);
          setCurAcFloorArea(ids);
        }
      } else {
        setCurAcFloorArea([]);
        if (acArea.length) {
          dispatch(updateAcCurrentArea(acArea[0]));
        }
      }
    }
  }, [acAreaVisible, curAcArea, acArea, acFloorSpaceList]);

  const getPids = (id: number, ids: number[], list: FloorAreaTreeItemType[]) => {
    if (ids.length === 0) {
      ids.unshift(id);
    }
    const cur = list.find((item) => item.id === id);
    if (cur) {
      if (cur.pid !== 0) {
        ids.unshift(cur.pid);
        getPids(cur.pid, ids, list);
      }
    }
  };

  const generateYears = () => {
    if (!yearList.length) {
      const years = [];
      const now = new Date().getFullYear();
      for (let y = now; 2019 <= y; y--) {
        years.push({
          label: y,
          value: y,
        });
      }
      setYearList(years);
      dispatch(updateSelecteYear(now));
    }
  };

  const handelToHome = () => {
    navigate('/select');
  };

  const handleGetSeason = () => {
    getSeason().then((res) => {
      if (res.code === 0 && res.data) {
        setSeason(res.data.season);
        dispatch(updateAcTempRange([res.data.tmpMin, res.data.tmpMax]));
        dispatch(updateAcTempCtrlAttrs(res.data.attrs || []));
      }
    });
  };

  const handleToUser = () => {
    navigate('/user');
  };

  const handleClose = () => {
    setUserDialogVisible(false);
  };
  const toggleConfirmCancel = (visible: boolean) => {
    setConfirmVisible(visible);
  };

  const handleLogout = () => {
    toggleConfirmCancel(false);
    dispatch(toggleLoading(true));
    dispatch(logout()).then(() => {
      handleClose();
      dispatch(toggleLoading(false));
      navigate('/login');
    });
  };
  const handelYearChange = (val: unknown) => {
    dispatch(updateSelecteYear(val as number));
  };
  const handeLampAreaChange = (val: unknown) => {
    const curId = lampFloorAreaList.find((item) => item.id === last(val))?.controlAreaId;
    if (curId) {
      const curArea = lampArea.find((item) => item.id === curId);
      dispatch(updateLampCurrentArea(cloneDeep(curArea)));
    }
  };
  const handeAcAreaChange = (val: unknown, option: any) => {
    const curId = acFloorSpaceList.find((item) => item.id === last(val))?.controlAreaId;
    if (curId) {
      const tmpSpace = acArea.find((item) => item.id === curId);
      dispatch(updateAcCurrentArea(cloneDeep(tmpSpace)));
    }
  };
  const handleSeasonClose = () => {
    // TODO 更新左下方的环境温度，看看是通过ws推送还是自己调接口
  };
  const menu = (
    <Menu
      items={[
        {
          key: 'setting',
          label: '个人设置',
          onClick: () => setUserDialogVisible(true),
        },
        {
          key: 'logout',
          label: '退出登录',
          onClick: () => toggleConfirmCancel(true),
        },
      ]}
    />
  );
  return (
    <>
      <header className="header">
        <div className="header-left">
          {homeVisible && <span className="iconfont icon-home-01" onClick={handelToHome} />}
          {yearVisible && (
            <BdiaSelect
              size="small"
              style={{ width: '0.81rem' }}
              options={yearList}
              value={selectYear}
              onChange={handelYearChange}
            />
          )}

          {lampAreaVisible && (
            <BdiaCascader
              allowClear={false}
              options={lampFloorAreaTree}
              fieldNames={{
                label: 'name',
                value: 'id',
              }}
              style={{
                width: '1.96rem',
              }}
              size="small"
              themeStyle="dark"
              value={curLampFloorArea}
              onChange={handeLampAreaChange}
            />
          )}
          {acAreaVisible && (
            <BdiaCascader
              allowClear={false}
              options={acFloorSpaceTree}
              fieldNames={{
                label: 'name',
                value: 'id',
              }}
              style={{
                width: '1.96rem',
              }}
              size="small"
              themeStyle="dark"
              value={curAcFloorArea}
              onChange={handeAcAreaChange}
            />
          )}
          {(lampAreaVisible || acAreaVisible) && (
            <span className="header-time-range">智能控制时段：{timeRangeStr}</span>
          )}
        </div>
        <div className="header-middle">
          {!window.isSensitive && <img src={LogoPng} />}
          {window.isSensitive ? 'xxxxx' : '大兴机场航站楼能源系统智慧调控系统'}
        </div>
        <div className="header-right">
          {settingVisible && editAuth && (
            <span className="iconfont icon-shezhi-01 header-setting-icon" onClick={handleToUser} />
          )}
          {!!season && <Season season={season} onClose={handleSeasonClose} updateSeason={handleGetSeason} />}
          {alarmVisible && <BdiaAlarm />}
          {location.pathname !== '/login' && (
            <Dropdown overlay={menu} trigger={['click']} overlayClassName="bdia-dark-dropdown">
              <div className="header-icon">
                <span className="iconfont icon-as-user" />
              </div>
            </Dropdown>
          )}
        </div>
      </header>
      {userDialogVisible && <UserDialog handleClose={handleClose} />}
      {confirmVisible && (
        <BdiaConfirm content="确定退出登录？" onCancel={() => toggleConfirmCancel(false)} onOk={handleLogout} />
      )}
    </>
  );
};
export default Header;

import { createAsyncThunk } from '@reduxjs/toolkit';
import request, { download } from './request';
import { EnumGroupStatus } from '@/typings/enum';

// 获取空调空间层级
export const getAcFloorSpace = createAsyncThunk('ac/floorSpaceList', async () => {
  return await request('space/air_con', {
    method: 'GET',
  });
});
// 查询当前控制季节
export const getSeason = () => {
  return request('air_con/current/season', {
    method: 'GET',
  });
};
// 区域列表
export const getAreaList = createAsyncThunk('ac/areaList', async () => {
  return await request('air_con/area', {
    method: 'GET',
  });
});
// 机组列表
export const getBaseGroupList = createAsyncThunk(
  'lamp/listStatus',
  async (params: { areaId: number; typeId?: number }) => {
    return await request('air_con/unit/list', {
      method: 'GET',
      body: params,
    });
  },
);
// 全部机组的快捷操作  把组合机组和区域机组整合后的编码统一放到codes中
export const putAllShortcut = (params: {
  controlType?: 0 | 1 | 2;
  controlAttr?: {}[];
  codes?: string[];
  areaId: number;
  shortControlType: 0 | 1;
}) => {
  return request('control/air_con/shortcut', {
    method: 'PUT',
    body: params,
  });
};
// 环境温度
export const getTemperature = (params: { areaId: number }) => {
  return request('air_con/ambient/temperature', {
    method: 'GET',
    body: params,
  });
};
// 获取机组详情
export const getGroupDetail = (params: { code: string }) => {
  return request('air_con/unit/detail', {
    method: 'GET',
    body: params,
  });
};
// 控制前机组状态查询
export const getGroupLatestStatus = (params: { code: string }) => {
  return request('air_con/unit/latest-status', {
    method: 'GET',
    body: params,
  });
};
// 信息卡中的机组操作
export const putGroupShortcut = (params: { code: string; areaId: number; controlType: number; controlAttr?: {}[] }) => {
  return request('control/air_con/unit', {
    method: 'PUT',
    body: params,
  });
};
// 批量获取组合机组和区域机组
export const getAcGroups = (params: { areaId: number }) => {
  return request('air_con/list/by-area-comb', {
    method: 'GET',
    body: params,
  });
};
// 获取温度列表
export const getEnvTemperature = (params: { areaId: number }) => {
  return request('air_con/ambient/temperature', {
    method: 'GET',
    body: params,
  });
};
// 异常报警未处理列表
export const getAcUnhandleList = () => {
  return request('alarm/air_con/unhandled', {
    method: 'GET',
  });
};
// 全部列表
export const getAcAllAlarmList = (params: AlarmListParams) => {
  return request('alarm/air_con/all', {
    method: 'GET',
    body: params,
  });
};
// 标记处理
export const acMarkHandled = (params: { id: number }) => {
  return request('alarm/air_con/mark-handled', {
    method: 'PUT',
    body: params,
  });
};
// 全部列表下载
export const downloadAcAlarmList = (data: { startDate?: string; endDate?: string }) =>
  download('alarm/air_con/download', data);

export interface AlarmListParams {
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
}

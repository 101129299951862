import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import type { Moment } from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import BdiaButton from '@/components/BdiaButton/BdiaButton';
import BdiaConfirm from '@/components/BdiaConfirm/BdiaConfirm';
import BdiaMessage from '@/components/BdiaMessage/BdiaMessage';
import AlarmAudio from '@/assets/audio/alarm.mp3';
import {
  getUnhandleList,
  markHandled,
  AlarmListParams,
  getAllAlarmList,
  downloadAlarmList,
  checkGroupIsDeleted,
  AlarmCommonParams,
  delAlarm,
  AlarmDelParams,
} from '@/api/lampControl';
import { getAcUnhandleList, acMarkHandled, getAcAllAlarmList, downloadAcAlarmList } from '@/api/acControl';
import { updateAlarmLocate } from '@/store/features/commonSlice';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { AlarmTypeList, EnumAlarm, EnumUserRole } from '@/typings/enum';
import ws from '@/utils/webSocketClass';
import './BdiaAlarm.less';
import BdiaDateRangePicker, { RangeValue } from '../BdiaDatePicker/BdiaDateRangePicker';
import { useLocation } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import BdiaSelect from '../BdiaSelect/BdiaSelect';

const CheckboxGroup = Checkbox.Group;

interface ListParams {
  id: number;
  time: string;
  title: string;
  category: 'GROUP' | 'SYSTEM' | 'TURNTABLE' | 'AIR_CON_UNIT';
  content: string;
  code: string;
  areaId: number;
  status?: 0 | 1; // 待处理 | 已处理
}

const DATE_FORMAT = 'YYYYMMDD';

export default function Alarm() {
  const location = useLocation();
  const { userinfo, alarmLocate } = useAppSelector((state) => state.common);
  const reduxDispatch = useAppDispatch();
  const editAuth = userinfo.role.some((r) => r !== EnumUserRole.LIGHT_VIEWER && r !== EnumUserRole.AIR_CON_VIEWER);
  const [curModule, setCurModule] = useState<string>();
  const [visible, setVisible] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<'unhandle' | 'all'>('unhandle');
  const [unhandleList, setUnhandleList] = React.useState<ListParams[]>([]);
  const [selectedOfUnhandleList, setSelectedOfUnhandleList] = useState<number[]>([]);
  const [allList, setAllList] = React.useState<ListParams[]>([]);
  const [selectedOfAllList, setSelectedOfAllList] = useState<number[]>([]);
  const [delRecord, setDelRecord] = useState<ListParams | number[] | undefined>(undefined);
  const [delRecordConfirm, setDelRecordConfirm] = useState<boolean>(false);
  const [total, setTotal] = React.useState<number>(0); // 全部列表的条数
  const [searchParams, setSearchParams] = React.useState<{
    page: number;
    dateRange: RangeValue;
    logCategory?: EnumAlarm;
  }>({
    page: 1,
    dateRange: [moment().subtract(30, 'days'), moment()],
  });
  const [tmpDates, setTmpDates] = useState<RangeValue>(null);
  const [selectError, setSelectError] = React.useState<ListParams | null | number[]>(); // 即将处理的报警
  const [audioPlaying, setAudioPlaying] = React.useState<boolean>(false);
  const audio = React.useRef<HTMLAudioElement>();
  const [audioText, setAudioText] = React.useState<string>();
  let [wsmsg, setWsmsg] = React.useState<any>(null);

  useEffect(() => {
    if (!window.clickHasOccurred) {
      setAudioText('因浏览器策略限制，刷新页面后需点击确认按钮帮助本系统重新获取播放报警音频的权限。');
    }
    ws.updateOpenEvt('alarm', handleWsOpen, 'add');
    return () => {
      const { pathname } = location;
      if (pathname.startsWith('/lamp/')) {
        ws.unSubscribeWS(4, 'lampAlarm');
        ws.unSubscribeWS(5, 'lampRelieve');
        ws.updateOpenEvt('alarm', handleWsOpen, 'del');
      } else if (pathname.startsWith('/ac/')) {
        // todo 空调的取消订阅
      }
    };
  }, []);

  useEffect(() => {
    const { pathname } = location;
    setCurModule(pathname.split('/')[1]);
  }, [location]);

  useEffect(() => {
    if (curModule) {
      getUnhandle();
    }
    if (curModule === 'lamp') {
      lampInit();
    } else if (curModule === 'ac') {
      acInit();
    }
  }, [curModule]);

  useEffect(() => {
    if (wsmsg) {
      if (wsmsg.type === 4) {
        alarmListening(wsmsg);
      } else if (wsmsg.type === 5) {
        relieveListening(wsmsg);
      }
    }
  }, [wsmsg]);
  useEffect(() => {
    getAll();
    if (searchParams.page === 1) {
      setSelectedOfAllList([]);
    }
  }, [searchParams]);

  const lampInit = () => {
    ws.subscribeWS(4, 'lampAlarm', (msg: any) => setWsmsg(msg));
    ws.subscribeWS(5, 'lampRelieve', (msg: any) => setWsmsg(msg));
  };
  const acInit = () => {
    // todo 空调订阅ws
  };

  // ws重连时更新列表
  const handleWsOpen = () => {
    getUnhandle();
  };

  const audioPlay = () => {
    if (!audio.current) {
      audio.current = new Audio(AlarmAudio);
      audio.current.controls = true;
    }
    setAudioPlaying(true);
    audio.current?.play();
    let start = 1;
    let times = 3;
    audio.current.addEventListener('ended', () => {
      if (start <= times) {
        audio.current?.play();
        start++;
      } else {
        audio.current?.play();
        window.addEventListener('mousemove', () => {
          audio.current?.pause();
          setAudioPlaying(false);
        });
      }
    });
  };

  // 异常监听
  const alarmListening = (msg: any) => {
    setVisible(true);
    if (tab === 'all') {
      setTab('unhandle');
    }
    // 更新全部列表
    searchParams.page = 1;
    searchParams.dateRange = [moment().subtract(30, 'days'), moment()];
    setSearchParams({ ...searchParams });
    // 更新未处理列表
    const list = [msg.data, ...unhandleList];
    setUnhandleList(list);
    if (!audioPlaying) {
      audioPlay();
    }
  };

  // 已处理异常监听
  const relieveListening = (msg: any) => {
    const ids: number[] = msg.data.ids;
    if (ids?.length) {
      // 更新未处理列表
      delMsgInUnhandleList(ids);

      // 修改全部列表数据
      allList.forEach((item) => {
        if (ids.indexOf(item.id) >= 0) {
          item.status = 1;
        }
      });
      setAllList(cloneDeep(allList));
    }
  };

  // 在未处理列表中删除消息
  const delMsgInUnhandleList = (ids: number[]) => {
    const newList = unhandleList.filter((item) => ids.indexOf(item.id) < 0);
    setUnhandleList(cloneDeep(newList));
  };

  const getUnhandle = () => {
    if (curModule === 'lamp') {
      getUnhandleList().then((res) => {
        if (res.code === 0) {
          setUnhandleList(res.data);
        }
      });
    } else if (curModule === 'ac') {
      getAcUnhandleList().then((res) => {
        if (res.code === 0) {
          setUnhandleList(res.data);
        }
      });
    }
  };

  const getAll = () => {
    const params: AlarmListParams = {
      page: searchParams.page,
      size: 20,
      logCategory: searchParams.logCategory,
    };
    if (searchParams.dateRange?.length) {
      params.startDate = searchParams.dateRange[0]?.format(DATE_FORMAT);
      params.endDate = searchParams.dateRange[1]?.format(DATE_FORMAT);
    }
    if (curModule === 'lamp') {
      getAllAlarmList(params).then((res) => {
        if (res.code === 0) {
          if (params.page === 1) {
            setAllList(res.data.list);
          } else {
            setAllList(allList.concat(res.data.list));
          }
          setTotal(res.data.total);
        }
      });
    } else if (curModule === 'ac') {
      getAcAllAlarmList(params).then((res) => {
        if (res.code === 0) {
          if (params.page === 1) {
            setAllList(res.data.list);
          } else {
            setAllList(allList.concat(res.data.list));
          }
          setTotal(res.data.total);
        }
      });
    }
  };

  const handleShowList = () => {
    setVisible(!visible);
  };

  const UnhandleClick = () => {
    if (tab === 'unhandle') {
      return;
    }
    setTab('unhandle');
  };

  const handle = (info: ListParams) => {
    setSelectError(info);
  };

  const HandleClick = () => {
    if (tab === 'all') {
      return;
    }
    setTab('all');
    if (!allList.length) {
      getAll();
    }
  };

  const handleConfrim = () => {
    if (selectError) {
      if (curModule === 'lamp') {
        let param = Array.isArray(selectError) ? { ids: selectError.join(',') } : { id: selectError.id };
        markHandled(param).then((res) => {
          if (res.code === 0) {
            BdiaMessage.success('处理成功');
          }
          setSelectError(null);
          setSelectedOfUnhandleList([]);
        });
      } else if (curModule === 'ac') {
        acMarkHandled({ id: (selectError as ListParams).id }).then((res) => {
          if (res.code === 0) {
            BdiaMessage.success('处理成功');
          }
          setSelectError(null);
          setSelectedOfUnhandleList([]);
        });
      }
    }
  };

  const handleCancel = () => {
    setSelectError(null);
  };

  const handleDateChange = (dates: RangeValue) => {
    searchParams.dateRange = dates;
    searchParams.page = 1;
    setSearchParams({ ...searchParams });
  };

  const handleLoadMore = () => {
    searchParams.page = searchParams.page + 1;
    setSearchParams({ ...searchParams });
  };

  const handleDownload = () => {
    const params: AlarmCommonParams = {
      startDate: searchParams.dateRange?.[0]?.format(DATE_FORMAT),
      endDate: searchParams.dateRange?.[1]?.format(DATE_FORMAT),
      logCategory: searchParams.logCategory,
    };
    if (selectedOfAllList.length) {
      params.ids = selectedOfAllList.join(',');
    }
    if (curModule === 'lamp') {
      downloadAlarmList(params);
    } else if (curModule === 'ac') {
      downloadAcAlarmList(params);
    }
  };

  const getName = (info: ListParams) => {
    const type = info.category;
    if (type === 'GROUP') {
      return '灯组报警';
    }
    if (type === 'SYSTEM') {
      return '系统报警';
    }
    if (type === 'AIR_CON_UNIT') {
      return '机组报警';
    }
    return '设备报警';
  };

  const handleNameClick = (info: ListParams) => {
    if (info.category === 'GROUP') {
      checkGroupIsDeleted({ code: info.code }).then((res) => {
        if (res.code === 0) {
          const { deleted } = res.data;
          if (deleted) {
            BdiaMessage.error('灯组已被删除，定位无效');
          } else {
            reduxDispatch(
              updateAlarmLocate({
                code: info.code,
                areaId: info.areaId,
              }),
            );
          }
        }
      });
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setTmpDates([null, null]);
    } else {
      setTmpDates(null);
    }
  };

  const disabledDate = (current: Moment) => {
    if (!tmpDates) {
      return false;
    }
    const tooLate = tmpDates[0] && current.diff(tmpDates[0], 'days') > 360;
    const tooEarly = tmpDates[1] && tmpDates[1].diff(current, 'days') > 360;
    return !!tooEarly || !!tooLate;
  };
  const onUnhandleCheckAllChange = (e: CheckboxChangeEvent) => {
    const tmp: number[] = [];
    if (e.target.checked) {
      unhandleList.forEach((item) => {
        tmp.push(item.id);
      });
    }
    setSelectedOfUnhandleList(tmp);
  };
  const handleAlarm = () => {
    setSelectError(selectedOfUnhandleList);
  };
  const onAllListCheckAllChange = (e: CheckboxChangeEvent) => {
    const tmp: number[] = [];
    if (e.target.checked) {
      allList.forEach((item) => {
        tmp.push(item.id);
      });
    }
    setSelectedOfAllList(tmp);
  };
  const handleDelAlarm = (record?: ListParams) => {
    if (record) {
      setDelRecord(record);
    } else {
      setDelRecord(cloneDeep(selectedOfAllList));
    }
    setDelRecordConfirm(true);
  };
  const excuteDelRecord = () => {
    if (!delRecord) {
      return;
    }
    const params: AlarmDelParams = {
      startDate: searchParams.dateRange?.[0]?.format(DATE_FORMAT),
      endDate: searchParams.dateRange?.[1]?.format(DATE_FORMAT),
      logCategory: searchParams.logCategory,
    };
    if (Array.isArray(delRecord)) {
      params.deleteIds = selectedOfAllList;
    } else {
      params.deleteIds = [delRecord.id];
    }
    if (curModule === 'lamp') {
      delAlarm(params).then((res) => {
        if (res?.data) {
          BdiaMessage.success('删除成功');
          setDelRecordConfirm(false);
          setSearchParams({
            ...searchParams,
            page: 1,
          });
          setSelectedOfAllList([]);
        }
      });
    }
  };
  return (
    <div className="alarm-comp">
      <div className={classNames('alarm-comp-content', { 'alarm-comp-content-hidden': !visible })}>
        <div className="alarm-comp-content-top">
          <span className="iconfont icon-as-close" onClick={() => setVisible(false)} />
        </div>
        <div className="alarm-comp-content-tab">
          <div
            className={classNames('alarm-comp-content-tab-item', {
              'alarm-comp-content-tab-item-active': tab === 'unhandle',
            })}
            onClick={UnhandleClick}
          >
            未处理
          </div>
          <div
            className={classNames('alarm-comp-content-tab-item', {
              'alarm-comp-content-tab-item-active': tab === 'all',
            })}
            onClick={HandleClick}
          >
            全部
          </div>
        </div>
        {tab === 'unhandle' ? (
          <div className="alarm-comp-unhandle-content">
            <div className="alarm-comp-content-num">
              <Checkbox
                indeterminate={!!selectedOfUnhandleList.length && selectedOfUnhandleList.length < unhandleList.length}
                onChange={onUnhandleCheckAllChange}
                checked={selectedOfUnhandleList.length !== 0 && selectedOfUnhandleList.length === unhandleList.length}
              >
                {selectedOfUnhandleList.length
                  ? `已选择 ${selectedOfUnhandleList.length} 条`
                  : `共 ${unhandleList.length} 条`}
              </Checkbox>
              <BdiaButton size="mini" type="default" onClick={handleAlarm}>
                {selectedOfUnhandleList.length ? '批量处理' : '全部处理'}
              </BdiaButton>
            </div>
            {unhandleList.length ? (
              <div className="alarm-comp-content-list">
                {unhandleList.map((item, ind) => {
                  return (
                    <div
                      key={ind}
                      className={classNames('alarm-comp-content-item', {
                        // 'locate-alarm-content': alarmLocate.code && alarmLocate.code === item.code,
                      })}
                    >
                      <Checkbox
                        checked={selectedOfUnhandleList.indexOf(item.id) >= 0}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          if (checked) {
                            selectedOfUnhandleList.push(item.id);
                          } else {
                            const ind = selectedOfUnhandleList.findIndex((id) => id === item.id);
                            if (ind >= 0) {
                              selectedOfUnhandleList.splice(ind, 1);
                            }
                          }
                          setSelectedOfUnhandleList(cloneDeep(selectedOfUnhandleList));
                        }}
                      />
                      <div className={classNames('alarm-comp-content-info')}>
                        {editAuth && (
                          <div className="alarm-comp-unhandle-btn" onClick={() => handle(item)}>
                            未处理
                          </div>
                        )}
                        <div className="alarm-comp-content-info-time">
                          {getName(item)}：{item.time}
                        </div>
                        {item.title && (
                          <div
                            className={classNames('alarm-comp-content-info-name', {
                              'alarm-comp-content-info-name-hover': item.category === 'GROUP',
                            })}
                            onClick={() => handleNameClick(item)}
                          >
                            <span>{item.title}</span>
                            {item.category === 'GROUP' && <span className="iconfont icon-a-dingwei1" />}
                          </div>
                        )}
                        <div className="alarm-comp-content-info-desc">{item.content}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="alarm-comp-content-empty">
                <span className="iconfont icon-kongzhuangtai-01" />
                <span className="alarm-empty-text">无数据</span>
              </div>
            )}
          </div>
        ) : (
          <div className="alarm-comp-handle-content">
            <div className="alarm-comp-handle-content-top">
              <div className="alarm-comp-handle-content-date">
                <BdiaDateRangePicker
                  size="small"
                  // allowClear={false}
                  disabledDate={disabledDate}
                  // className="alarm-date-picker"
                  value={tmpDates || searchParams.dateRange}
                  onChange={handleDateChange}
                  onCalendarChange={(val) => setTmpDates(val)}
                  onOpenChange={onOpenChange}
                />
                <BdiaSelect
                  allowClear
                  options={AlarmTypeList}
                  fieldNames={{ value: 'type', label: 'name' }}
                  themeStyle="dark"
                  size="small"
                  style={{ width: '1.2rem' }}
                  value={searchParams.logCategory}
                  onChange={(value) => {
                    setSearchParams({
                      ...searchParams,
                      page: 1,
                      logCategory: value as EnumAlarm,
                    });
                  }}
                  placeholder="请选择"
                />
              </div>
            </div>
            <div className="alarm-comp-handle-content-middle">
              <Checkbox
                indeterminate={!!selectedOfAllList.length && selectedOfAllList.length < allList.length}
                onChange={onAllListCheckAllChange}
                checked={selectedOfAllList.length !== 0 && selectedOfAllList.length === allList.length}
              >
                {selectedOfAllList.length
                  ? `已选择 ${selectedOfAllList.length} 条`
                  : `共 ${total} 条，显示${allList.length}条`}
              </Checkbox>
              <span>
                {editAuth && (
                  <BdiaButton size="mini" type="default" onClick={handleDownload}>
                    {selectedOfAllList.length ? '批量' : '全部'}下载
                  </BdiaButton>
                )}
                <BdiaButton size="mini" type="default" onClick={() => handleDelAlarm()}>
                  {selectedOfAllList.length ? '批量' : '全部'}删除
                </BdiaButton>
              </span>
            </div>
            {allList.length ? (
              <div className="alarm-comp-content-list" id="scrollableDiv">
                <InfiniteScroll
                  dataLength={allList.length}
                  next={handleLoadMore}
                  hasMore={allList.length < total}
                  loader={<span>...</span>}
                  scrollableTarget="scrollableDiv"
                >
                  <div>
                    {allList.map((item, ind) => {
                      return (
                        <div key={ind} className={classNames('alarm-comp-content-item')}>
                          <Checkbox
                            checked={selectedOfAllList.indexOf(item.id) >= 0}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              if (checked) {
                                selectedOfAllList.push(item.id);
                              } else {
                                const ind = selectedOfAllList.findIndex((id) => id === item.id);
                                if (ind >= 0) {
                                  selectedOfAllList.splice(ind, 1);
                                }
                              }
                              setSelectedOfAllList(cloneDeep(selectedOfAllList));
                            }}
                          />
                          <div className="alarm-comp-content-info" key={ind}>
                            {!item.status && editAuth ? (
                              <div className="alarm-comp-unhandle-btn" onClick={() => handle(item)}>
                                未处理
                              </div>
                            ) : item.status ? (
                              <div className="alarm-comp-handle-text">
                                <span>已处理</span>
                                <BdiaButton
                                  size="mini"
                                  className="alarm-comp-handle-btn"
                                  onClick={() => handleDelAlarm(item)}
                                >
                                  删除
                                </BdiaButton>
                              </div>
                            ) : (
                              <div className="alarm-comp-unhandle-text">待处理</div>
                            )}

                            <div className="alarm-comp-content-info-time">
                              {getName(item)}：{item.time}
                            </div>
                            {item.title && (
                              <div
                                className={classNames('alarm-comp-content-info-name', {
                                  'alarm-comp-content-info-name-hover': item.category === 'GROUP',
                                })}
                                onClick={() => handleNameClick(item)}
                              >
                                <span>{item.title}</span>
                                {item.category === 'GROUP' && <span className="iconfont icon-a-dingwei1" />}
                              </div>
                            )}
                            <div className="alarm-comp-content-info-desc">{item.content}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            ) : (
              <div className="alarm-comp-content-empty">
                <span className="iconfont icon-kongzhuangtai-01" />
                <span className="alarm-empty-text">无数据</span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={classNames('alarm-comp-icon', { 'alarm-comp-icon-selected': visible })} onClick={handleShowList}>
        <span className="iconfont icon-baojing-01" />
        <span className="alarm-comp-num">{unhandleList.length}</span>
      </div>
      {selectError && <BdiaConfirm content={getHandleMsg(selectError)} onOk={handleConfrim} onCancel={handleCancel} />}
      {audioText && <BdiaConfirm content={audioText} onOk={() => setAudioText('')} />}
      {delRecordConfirm && delRecord && (
        <BdiaConfirm
          content={<DelConfirm info={delRecord} />}
          onCancel={() => {
            setDelRecordConfirm(false);
            setDelRecord(undefined);
          }}
          onOk={excuteDelRecord}
        />
      )}
    </div>
  );
}

const getHandleMsg = (errors: ListParams | number[]) => {
  if (Array.isArray(errors)) {
    if (errors.length) {
      return '选中的异常确认已处理完毕？';
    }
    return '确认全部的异常已处理完毕？';
  }
  return errors.time + ' 发生的异常确认已处理完毕？';
};

const DelConfirm = ({ info }: { info: ListParams | number[] }) => {
  const isSingle = !Array.isArray(info);
  return (
    <div>
      <div>
        {isSingle
          ? `${info.time} 发生的异常报警确认删除？`
          : `确认删除${info.length ? '选中的' : '全部'}异常报警记录？`}
      </div>
      {!isSingle && (
        <div style={{ opacity: 0.5, fontSize: '0.16rem', paddingTop: '0.06rem' }}>未处理的异常报警无法删除</div>
      )}
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { EnumGroupStatus, EnumGroupMode, EnumLampAreaType } from '@/typings/enum';
import { getAreaList, getBaseGroupList, getFloorAreaList } from '@/api/lampControl';
import { ViewOptions } from 'ol/View';
import { boundingExtent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
export interface MapViewOptions extends Omit<ViewOptions, 'extent'> {
  extent: Coordinate[];
}
export interface LampGroup {
  code: string;
  currentMode: EnumGroupMode;
  groupType: string;
  name: string;
  status: EnumGroupStatus;
  id: number;
  baseGroups: string[];
  brightness: number;
}
export interface AreaType {
  name: string;
  id: number;
  gisConfig: {
    backgroundUrl: string;
    tiles: string;
    images: string;
    source: string;
  };
  show: ViewOptions;
  edit?: ViewOptions;
  type: EnumLampAreaType;
}
interface FloorAreaType {
  name: string;
  pid: number;
  id: number;
  controlAreaId?: number;
}
export interface FloorAreaTreeItemType extends FloorAreaType {
  children?: FloorAreaTreeItemType[];
}
interface LampState {
  lampList: LampGroup[];
  shortcuts: number;
  areaList: AreaType[]; // 带有gis配置的区域list
  floorAreaList: FloorAreaType[]; // 楼层-区域list
  floorAreaTree: FloorAreaTreeItemType[]; // 树结构的floorAreaList
  currentArea: { id: number }; // 对应的是areaList的id,floorAreaList的controlAreaId
}

const initialState: LampState = {
  lampList: [],
  shortcuts: 0, // 数字累加，用于监听快捷操作
  areaList: [],
  floorAreaList: [],
  floorAreaTree: [],
  currentArea: { id: 0 },
};

export const lampSlice = createSlice({
  name: 'lamp',
  initialState,
  reducers: {
    // ws灯组状态更新
    updateLampListStatus: (
      state,
      { payload }: { payload: { code: string; status: EnumGroupStatus; bgn: number }[] },
    ) => {
      const { lampList } = state;
      lampList.forEach((item) => {
        const exist = payload.find((data) => data.code === item.code);
        if (exist) {
          item.status = exist.status;
          item.brightness = exist.bgn;
        }
      });
      state.lampList = lampList;
    },
    // ws灯组模式更新
    updateLampListMode: (state, { payload }) => {
      const { manualAll, modeChange }: { manualAll: boolean; modeChange?: { [code: string]: EnumGroupMode } } = payload;
      const { lampList } = state;
      if (manualAll) {
        lampList.forEach((item) => {
          item.currentMode = EnumGroupMode.MANUAL;
        });
      } else {
        if (modeChange) {
          lampList.forEach((item) => {
            if (typeof modeChange[item.code] === 'number') {
              item.currentMode = modeChange[item.code];
            }
          });
        }
      }
      state.lampList = lampList;
    },
    updateShortcuts: (state) => {
      state.shortcuts++;
    },
    updateCurrentArea: (state, { payload }) => {
      state.currentArea = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAreaList.fulfilled, (state, { payload }) => {
      const { data } = payload;
      const res: AreaType[] = [];
      data.forEach(
        (item: {
          name: string;
          id: number;
          gisConfig: string;
          show: MapViewOptions;
          edit?: MapViewOptions;
          type: EnumLampAreaType;
        }) => {
          const { gisConfig, show, edit, ...rest } = item;
          const obj: AreaType = {
            ...rest,
            gisConfig: JSON.parse(item.gisConfig),
            show: {
              ...show,
              extent: show.extent && boundingExtent(show.extent),
            },
          };
          if (edit) {
            obj.edit = {
              ...edit,
              extent: edit.extent && boundingExtent(edit.extent),
            };
          }
          res.push(obj);
        },
      );
      state.areaList = res;
    });
    builder.addCase(getBaseGroupList.fulfilled, (state, { payload }) => {
      state.lampList = payload.data || [];
    });
    builder.addCase(getFloorAreaList.fulfilled, (state, { payload }) => {
      const list = payload.data as FloorAreaTreeItemType[];
      const parent: FloorAreaTreeItemType[] = list.filter((item) => item.pid === 0);
      parent.forEach((item) => {
        item.children = getChildren(list, item.id);
      });
      state.floorAreaList = list;
      state.floorAreaTree = parent;
    });
  },
});

const getChildren = (list: FloorAreaType[], pid: number): FloorAreaTreeItemType[] => {
  const children: FloorAreaTreeItemType[] = [];
  list.forEach((item) => {
    if (item.pid === pid) {
      const cur = {
        ...item,
        children: getChildren(list, item.id),
      };
      children.push(cur);
    }
  });
  return children;
};

export const { updateLampListStatus, updateLampListMode, updateShortcuts, updateCurrentArea } = lampSlice.actions;

export default lampSlice.reducer;
